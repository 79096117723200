function initSalesforceFlow() {
  const loadSFScriptTimeout = setTimeout(loadSFScript, 100)

  function loadSFScript() {
    clearTimeout(loadSFScriptTimeout)
    const src =
      'https://apco-portal.my.salesforce-sites.com/lightning/lightning.out.js'
    const sfs = document.createElement('script')
    sfs.setAttribute('src', src)
    document.body.appendChild(sfs)

    pollForLightning()
  }

  const herokuId = window.herokuId || null
  const flowName = 'SF_EmailPreferenceCentre'

  function pollForLightning() {
    const lightningCheckInterval = setInterval(() => {
      if (typeof $Lightning !== 'undefined') {
        clearInterval(lightningCheckInterval)
        loadSFForm()
      }
    }, 500)
  }

  function loadSFForm() {
    $Lightning.use(
      'c:GenericEmbedFlowInExternalWebsitesApp',
      function () {
        let parameters = 'flowName=' + flowName

        if (herokuId) {
          parameters += '&herokuId=' + herokuId
        } else {
          const urlParams = new URLSearchParams(window.location.search)
          if (urlParams.get('website') != null) {
            parameters += '&website=' + urlParams.get('website')
          }
          if (urlParams.get('email') != null) {
            parameters += '&email=' + urlParams.get('email')
          }
        }

        $Lightning.createComponent(
          'c:GenericEmbedFlowInExternalWebsites',
          { parameters: parameters },
          'lightningLocator',
          function (cmp) {}
        )
      },
      'https://apco-portal.my.salesforce-sites.com/APCOWebToCase',
      'authToken'
    )
  }
}

document.addEventListener('turbolinks:load', function () {
  const lightningLocatorDiv = document.getElementById('lightningLocator')
  if (lightningLocatorDiv && lightningLocatorDiv.innerHTML.trim() === '') {
    initSalesforceFlow()
  }
})
